<template>
	<div>
		<v-card flat>
			<v-card-title
				class="text-h6 text-md-h5 pt-2 pt-md-4 pb-2 pb-md-4 titleColor"
			>
				機材詳細
				<v-spacer></v-spacer>
				<v-btn icon @click="$store.commit('set_dialogInfo_done_info', false)">
					<v-icon color="white">mdi-close-circle</v-icon>
				</v-btn>
			</v-card-title>

			<v-card-text>
				<div class="OutputPrintStyle" ref="OutputPrint">
					<v-list v-for="item in ListInfo_A" :key="item.id">
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									案件名：{{ item.projects }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item>
							<v-list-item-content>
								<v-list-item-title
									>ユーザー名：{{ item.user }}</v-list-item-title
								>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title
									>貸出日：{{ item.checkout_date }}</v-list-item-title
								>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title
									>返却日：{{ item.checkout_date }}</v-list-item-title
								>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>機材名：</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<v-data-table
						v-if="$vuetify.breakpoint.mdAndUp"
						:headers="headers"
						:items="ListInfo"
						:items-per-page="10"
						item-key="id+name"
						elevation="0"
						:sort-by="['model']"
						:sort-desc="false"
						:footer-props="{ 'items-per-page-text': '' }"
						hide-default-footer
					>
						<template v-slot:item.category="{ item }">
							{{
								item.category
									| replace("camera", "Camera")
									| replace("lens", "Lens")
									| replace("recorder", "Recorder / Monitor")
									| replace("media", "Media")
									| replace("tripod", "Tripod/Gimbal/Jib")
									| replace("light", "Light")
									| replace("switcher", "Switcher")
									| replace("video-cable", "Video cable")
									| replace("audio-cable", "Audio cable")
									| replace("lan-cable", "LAN cable")
									| replace("power-cable", "Power cable")
									| replace("other-cable", "Cable (Other)")
									| replace("labo", "Labo")
							}}
						</template>
						<template v-slot:item.location_repo="{ item }">
							{{
								item.location_repo
									| replace("tech_room", "Tech Room")
									| replace("stock_room", "Stock Room")
							}}
						</template>
						<template v-slot:item.location_box="{ item }">
							{{
								item.location_box
									| replace("a1light", "A-1-Light")
									| replace("a1camera", "A-1-Camera")
									| replace("a1lens", "A-1-Lens")
									| replace("a2camera", "A-2-Camera")
									| replace("a3case", "A-3-Case")
									| replace("a41", "A-4-1")
									| replace("a42", "A-4-2")
									| replace("a43", "A-4-3")
									| replace("b1jib", "B-1-Jib")
									| replace("b1light", "B-1-Light")
									| replace("b2sdi", "B-2-SDI")
									| replace("b24", "B-2-4")
									| replace("b25", "B-2-5")
									| replace("b31", "B-3-1")
									| replace("b32", "B-3-2")
									| replace("b33", "B-3-3")
									| replace("b34", "B-3-4")
									| replace("b35", "B-3-5")
									| replace("c1display", "C-1-Display")
									| replace("c21", "C-2-1")
									| replace("c22", "C-2-2")
									| replace("c23", "C-2-3")
									| replace("c24", "C-2-4")
									| replace("c25", "C-2-5")
									| replace("c31", "C-3-1")
									| replace("c32", "C-3-2")
									| replace("c33", "C-3-3")
									| replace("c34", "C-3-4")
									| replace("c35", "C-3-5")
							}}
						</template>
						<template v-slot:item.check>
							<v-icon>mdi-checkbox-blank-outline</v-icon>
						</template>
					</v-data-table>
					<v-list-item-group color="white" v-if="!$vuetify.breakpoint.mdAndUp">
						<v-list-item
							v-for="(item, i) in ListInfo"
							:key="i"
							:ripple="false"
							:inactive="false"
						>
							<v-row>
								<v-list-item-content>
									<v-divider></v-divider>
									<v-col cols="6" md="3">
										<div class="mobileList">
											<v-list-item-subtitle class="text-subtitle-2"
												>Category</v-list-item-subtitle
											>
											<v-list-item-subtitle class="text-subtitle-1">{{
												item.category
													| replace("camera", "Camera")
													| replace("lens", "Lens")
													| replace("recorder", "Recorder / Monitor")
													| replace("media", "Media")
													| replace("tripod", "Tripod/Gimbal/Jib")
													| replace("light", "Light")
													| replace("switcher", "Switcher")
													| replace("video-cable", "Video cable")
													| replace("audio-cable", "Audio cable")
													| replace("lan-cable", "LAN cable")
													| replace("power-cable", "Power cable")
													| replace("other-cable", "Cable (Other)")
													| replace("labo", "Labo")
											}}</v-list-item-subtitle>
										</div>
										<div class="py-1"></div>
										<div v-if="item.manufacturer" class="mobileList">
											<v-list-item-subtitle class="text-subtitle-2"
												>Manufacturer</v-list-item-subtitle
											>
											<v-list-item-subtitle
												class="text-subtitle-1"
												v-text="item.manufacturer"
											></v-list-item-subtitle>
										</div>
										<div class="py-1"></div>
										<div class="mobileList">
											<v-list-item-subtitle class="text-subtitle-2"
												>Model</v-list-item-subtitle
											>
											<v-list-item-subtitle
												class="text-subtitle-1"
												v-text="item.model"
											></v-list-item-subtitle
											><span v-if="item.size">{{ item.size }}m</span>
										</div>
										<div class="py-1"></div>
										<div v-if="item.model_number" class="mobileList">
											<v-list-item-subtitle class="text-subtitle-2"
												>Model number</v-list-item-subtitle
											>
											<v-list-item-subtitle
												class="text-subtitle-1"
												v-text="item.model_number"
											></v-list-item-subtitle>
										</div>
									</v-col>
									<v-col cols="6" md="3">
										<div class="mobileList">
											<v-list-item-subtitle class="text-subtitle-2"
												>Location</v-list-item-subtitle
											>
											<v-list-item-subtitle class="text-subtitle-1">{{
												item.location_repo
													| replace("tech_room", "Tech Room")
													| replace("stock_room", "Stock Room")
											}}</v-list-item-subtitle>
										</div>
										<div class="py-1"></div>
										<div class="mobileList">
											<v-list-item-subtitle class="text-subtitle-2"
												>Box</v-list-item-subtitle
											>
											<v-list-item-subtitle class="text-subtitle-1">{{
												item.location_box
													| replace("a1light", "A-1-Light")
													| replace("a1camera", "A-1-Camera")
													| replace("a1lens", "A-1-Lens")
													| replace("a2camera", "A-2-Camera")
													| replace("a3case", "A-3-Case")
													| replace("a41", "A-4-1")
													| replace("a42", "A-4-2")
													| replace("a43", "A-4-3")
													| replace("b1jib", "B-1-Jib")
													| replace("b1light", "B-1-Light")
													| replace("b2sdi", "B-2-SDI")
													| replace("b24", "B-2-4")
													| replace("b25", "B-2-5")
													| replace("b31", "B-3-1")
													| replace("b32", "B-3-2")
													| replace("b33", "B-3-3")
													| replace("b34", "B-3-4")
													| replace("b35", "B-3-5")
													| replace("c1display", "C-1-Display")
													| replace("c21", "C-2-1")
													| replace("c22", "C-2-2")
													| replace("c23", "C-2-3")
													| replace("c24", "C-2-4")
													| replace("c25", "C-2-5")
													| replace("c31", "C-3-1")
													| replace("c32", "C-3-2")
													| replace("c33", "C-3-3")
													| replace("c34", "C-3-4")
													| replace("c35", "C-3-5")
											}}</v-list-item-subtitle>
										</div>

										<div class="py-1"></div>
										<div class="mobileList">
											<v-list-item-subtitle class="text-subtitle-2"
												>Amount</v-list-item-subtitle
											>
											<v-list-item-subtitle
												class="text-subtitle-1"
												v-text="item.quantity"
											></v-list-item-subtitle>
										</div>
										<div class="py-1"></div>
										<div class="mobileList">
											<v-list-item-subtitle class="text-subtitle-2"
												>Check user</v-list-item-subtitle
											>
											<v-list-item-subtitle
												class="text-subtitle-1"
												v-text="item.report_check"
											></v-list-item-subtitle>
										</div>
									</v-col>
								</v-list-item-content>
							</v-row>
						</v-list-item>
					</v-list-item-group>
				</div>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="#262626"
					elevation="0"
					outlined
					@click="print"
					class="rounded-0"
				>
					Print
				</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
	export default {
		name: "Done",
		filters: {
			replace: function (st, rep, repWith) {
				const result = st.split(rep).join(repWith);
				return result;
			},
		},
		props: { ListInfo: Array, ListInfo_A: Array },
		methods: {
			print() {
				this.$print(this.$refs.OutputPrint);
			},
		},
		data: () => ({
			headers: [
				{ text: "Check", value: "check" },
				{ text: "Manufacturer", value: "manufacturer" },
				{ text: "Model", value: "model" },
				{ text: "Category", value: "category" },
				{ text: "Location", value: "location_repo" },
				{ text: "Box", value: "location_box" },
				{ text: "Cable length", value: "size" },
				{ text: "Quantity", value: "quantity", sortable: false },
				{ text: "Check user", value: "report_check", sortable: false },
			],
			ecosystem: [
				{
					text: "vuetify-loader",
					href: "https://github.com/vuetifyjs/vuetify-loader",
				},
				{
					text: "github",
					href: "https://github.com/vuetifyjs/vuetify",
				},
				{
					text: "awesome-vuetify",
					href: "https://github.com/vuetifyjs/awesome-vuetify",
				},
			],
			importantLinks: [
				{
					text: "Documentation",
					href: "https://vuetifyjs.com",
				},
				{
					text: "Chat",
					href: "https://community.vuetifyjs.com",
				},
				{
					text: "Made with Vuetify",
					href: "https://madewithvuejs.com/vuetify",
				},
				{
					text: "Twitter",
					href: "https://twitter.com/vuetifyjs",
				},
				{
					text: "Articles",
					href: "https://medium.com/vuetify",
				},
			],
			whatsNext: [
				{
					text: "Explore components",
					href: "https://vuetifyjs.com/components/api-explorer",
				},
				{
					text: "Select a layout",
					href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
				},
				{
					text: "Frequently Asked Questions",
					href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
				},
			],
		}),
	};
</script>
<style>
	@media print {
		.OutputPrintStyle .v-list-item__title {
			font-size: 0.5rem;
		}

		.OutputPrintStyle .v-expansion-panel-header {
			font-size: 0.5rem;
		}

		.OutputPrintStyle
			.v-data-table
			> .v-data-table__wrapper
			> table
			> tbody
			> tr
			> td {
			font-size: 0.5rem !important;
		}

		.OutputPrintStyle
			.v-data-table
			> .v-data-table__wrapper
			> table
			> thead
			> tr
			> th {
			font-size: 0.2rem !important;
		}
	}
</style>

<style scoped>
	.mobileList {
		border-left: 2px solid #262626;
		padding: 2px 8px;
	}

	.v-list-item__content {
		align-items: flex-start !important;
	}
	::v-deep .v-list-item__subtitle {
		white-space: normal;
	}
</style>
